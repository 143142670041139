<template>
  <div :key="key" class="mt-70-px">
    <navbar :docsName="docsName"/>
    <!-- Start Login -->

    <!-- Ende Login -->
    <div v-if="angemeldeterMitarbeiter" class="container-fluid tile-container">
      <StartseiteEducation
        v-if="$CONST('CONFIG').APPTYPE == 'Education'"
      ></StartseiteEducation>
      <StartseiteMembership
        v-if="$CONST('CONFIG').APPTYPE === 'Membership'"
      ></StartseiteMembership>
      <StartseiteDESKA
        v-if="$CONST('CONFIG').APPTYPE == 'DESKA'"
      ></StartseiteDESKA>
      <StartseiteERP v-if="$CONST('CONFIG').APPTYPE == 'ERP'"></StartseiteERP>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import StartseiteEducation from "@/pages/Startseiten/Startseite Education";
import StartseiteMembership from "@/pages/Startseiten/Startseite Membership";
import StartseiteDESKA from "@/pages/Startseiten/Startseite DESKA";
import StartseiteERP from "@/pages/Startseiten/Startseite ERP";
import page from "@/mixins/Page";
import store from "@/store";

import Mitarbeiter from "@/models/Mitarbeiter";

import Api from "@/Api";

import { uuidv4 } from "@/utils/Uuid";

import { LOAD_DASHBOARD_INFO } from "@/store/dashboard/actions.type";

export default {
  name: "Startseite",
  title: "{{ $CONST('CONFIG').APPNAME }}",
  components: {
    Navbar,
    StartseiteEducation,
    StartseiteMembership,
    StartseiteDESKA,
    StartseiteERP,
  },
  mixins: [page],
  store,
  metaInfo() {
    return {
      titleTemplate: () => {
        return this.$CONST("CONFIG").APPNAME;
      },
    };
  },
  data() {
    return {
      name: "",
      salutation: "",
      key: uuidv4(),
      infoStudierende: 0,
      statistikAnmeldungen: null,
      isDozent: false,
    };
  },
  computed: {
    artikeltypen() {
      return this.$store.state.artikeltypen;
    },
    belegtypen() {
      return this.$store.state.belegtypen;
    },
    permissions() {
      return this.$store.state.permissions;
    },
    statistiken() {
      return this.$store.state.belegstatistik;
    },
    dashboardInfo: {
      get() {
        return this.$store.state.dashboard.dashboardInfo;
      },
    },
  },
  created() {
    this.name = Mitarbeiter.fullName(this.angemeldeterMitarbeiter);

    let hours = new Date().getHours();

    if (hours < 11 && hours > 0) {
      this.salutation = "Guten Morgen";
    } else if (hours >= 11 && hours < 18) {
      this.salutation = "Guten Tag";
    } else {
      this.salutation = "Guten Abend";
    }

    /* Statistik für Anmeldungen */

    Api.get("/statistik/", { params: { type: "anmeldungen" } }).then(
      (response) => {
        this.statistikAnmeldungen = response.data;
      }
    );

    /* Infoboxen für Frontend */

    if (this.dashboardInfo.studierende == 0)
      this.$store.dispatch(`dashboard/${LOAD_DASHBOARD_INFO}`);
  },
  mounted() {
    if (
      this.gruppen.find(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").INSTRUCTOR
      )
    )
      this.isDozent = true;
  },
};
</script>

<style lang="scss">
</style>
