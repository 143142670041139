<template>
  <div class="row pt-1" v-if="berechtigungen.m_geschaeftspartner.read">
    <div class="col-lg-12">
      <div class="tile-categorie-frame">
        <div class="tile-categorie">
          <div class="header">
            <span>{{ $t("global.dashboard_crm") }}</span>
          </div>
          <div class="body">
            <div class="row">
              <div class="col-xl-12">
                <div class="tile-frame left-aligned h-1">
                  <router-link to="/geschaeftspartner/personenliste">
                    <div class="tile bg-med-green inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-user-friends"
                          fixed-width
                        />
                      </div>
                      <div class="title">
                        <div>{{ $t("global.contacts") }}</div>
                      </div>
                      <div class="informations">
                        <div class="row">
                          <div class="col-xl-8 col-lg-8 col-md-8 col-8"></div>
                          <div class="col-xl-4 col-lg-4 col-md-4 col-4">
                            <router-link
                              :to="{
                                name: 'studierende-liste',
                                params: { rolleProp: '174BFC21086' },
                              }"
                            >
                              <div class="information fs-16">
                                <font-awesome-layers class="fa-lg">
                                  <font-awesome-icon
                                    icon="fa-solid fa-circle"
                                    transform="down-2"
                                  />
                                  <font-awesome-icon
                                    icon="fa-solid fa-user-graduate"
                                    transform="shrink-6 down-2"
                                    :style="{ color: 'white' }"
                                  />
                                </font-awesome-layers>
                                <b-spinner
                                  v-if="dashboardInfoIsBusy"
                                  small
                                  class="align-middle ml-1"
                                ></b-spinner>
                                <span
                                  v-if="!dashboardInfoIsBusy"
                                  class="fs-14 ml-1"
                                  >{{ dashboardInfo.studierende }}</span
                                >
                              </div></router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <div class="col-xl-12">
                <div class="tile-frame left-aligned h-1">
                  <router-link to="/geschaeftspartner/firmenliste">
                    <div class="tile bg-med-green inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon icon="fa-duotone fa-building" />
                      </div>
                      <div class="title">
                        <div>{{ $t("global.dashboard_companies") }}</div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <!-- Ende Präsenzliste -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOAD_DASHBOARD_INFO } from "@/store/dashboard/actions.type";
import { BSpinner } from "bootstrap-vue";

export default {
  name: "StartseiteCRM",
  components: { BSpinner },
  mixins: [],
  props: {},
  data() {
    return {
      dashboardInfoIsBusy: false,
    };
  },
  created() {
    if (this.dashboardInfo.studierende == 0) {
      this.dashboardInfoIsBusy = true;
      this.$store.dispatch(`dashboard/${LOAD_DASHBOARD_INFO}`).then(() => {
        this.dashboardInfoIsBusy = false;
      });
    }
  },
  mounted() {},
  computed: {
    dashboardInfo: {
      get() {
        return this.$store.state.dashboard.dashboardInfo;
      },
    },
  },
  methods: {},
};
</script>
