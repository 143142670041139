<template>
  <div v-if="berechtigungen.m_finanzen.read">
    <div class="row pt-1">
      <div class="col-lg-12">
        <div class="tile-categorie-frame">
          <div class="tile-categorie">
            <div class="header">
              <span>{{ $t("global.dashboard_registrations") }}</span>
            </div>
            <div class="body">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12">
                  <pie-chart
                    :data="statistikAnmeldungen"
                    :colors="[
                      '#add58c',
                      '#1ad7e1',
                      '#ade0ec',
                      '#c3c4e2',
                      '#fccb9d',
                      '#f4f3d5',
                    ]"
                    :legend="false"
                  ></pie-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";

export default {
  name: "StartseiteAnmeldungenStatistik",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      statistikAnmeldungen: null,
    };
  },
  created() {
    Api.get("/statistik/", { params: { type: "anmeldungen" } }).then(
      (response) => {
        this.statistikAnmeldungen = response.data;
      }
    );
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
