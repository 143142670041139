<template>
  <div class="row">
    <!-- LEFT COLUMN -->

    <div class="col-xl-2 col-lg-12">
      <div class="row pt-4" v-if="berechtigungen.m_geschaeftspartner.read">
        <!-- CRM -->
        <div class="col-lg-12">
          <div class="tile-categorie-frame">
            <div class="tile-categorie">
              <div class="header">
                <span>CRM</span>
              </div>
              <div class="body">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link to="/geschaeftspartner/personenliste">
                        <div class="tile bg-med-green inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-user-friends"
                            />
                          </div>
                          <div class="title">
                            <div>Personen</div>
                          </div>
                          <div class="informations">
                            <div class="row">
                              <div
                                class="col-xl-8 col-lg-8 col-md-8 col-8"
                              ></div>
                              <div class="col-xl-4 col-lg-4 col-md-4 col-4">
                                <router-link
                                  to="/geschaeftspartner/studierende/"
                                >
                                  <div class="information fs-16">
                                    <font-awesome-layers class="fa-lg">
                                      <font-awesome-icon
                                        icon="fa-solid fa-circle"
                                        transform="down-2"
                                      />
                                      <font-awesome-icon
                                        icon="fa-solid fa-user-graduate"
                                        transform="shrink-6 down-2"
                                        :style="{ color: 'white' }"
                                      />
                                    </font-awesome-layers>
                                    <span class="fs-14 ml-1">{{
                                      dashboardInfo.studierende
                                    }}</span>
                                  </div></router-link
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>

                  <!--<div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link to="/geschaeftspartner/adressenliste">
                        <div class="tile bg-med-green inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon icon="fa-duotone fa-user-friends"/>
                          </div>
                          <div class="title">
                            <div>Adressen</div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MID RIGHT COLUMN -->

    <div class="col-xl-2 col-lg-12">
      <!-- Ressourcen -->

      <div class="row pt-4" v-if="berechtigungen.m_ressourcen.read">
        <div class="col-lg-12">
          <div class="tile-categorie-frame">
            <div class="tile-categorie">
              <div class="header">
                <span>Reservationen</span>
              </div>
              <div class="body">
                <div class="row">
                  <div class="col-xl-12" v-if="berechtigungen.m_termin.read">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'Terminliste' }">
                        <div class="tile bg-bright-orange inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-calendar-day"
                            />
                          </div>
                          <div class="title">Termine</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'Reservationen' }">
                        <div class="tile bg-bright-orange inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-calendar-day"
                            />
                          </div>
                          <div class="title">Reservationen</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'termin-kalender' }">
                        <div class="tile bg-bright-orange inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-calendar-alt"
                            />
                          </div>
                          <div class="title">Kalender</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12">
                  <div class="tile-frame centered h-2">
                    <router-link :to="{ name: 'Arbeitsplatzliste' }">
                      <div class="tile bg-bright-orange inverted">
                        <div class="icon-container fa-container">
                          <font-awesome-icon icon="fa-duotone fa-desktop" />
                        </div>
                        <div class="title">Verwaltung</div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- RIGHT COLUMN -->

    <div class="col-xl-2 col-lg-12">
      <!-- Korrespondenz -->

      <div class="row pt-4" v-if="berechtigungen.m_korrespondenz.read">
        <div class="col-lg-12">
          <div class="tile-categorie-frame">
            <div class="tile-categorie">
              <div class="header">
                <span>Kommunikation</span>
              </div>
              <div class="body">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'korrespondenz-liste' }">
                        <div class="tile bg-turkis inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon icon="fa-duotone fa-print" />
                          </div>
                          <div class="title">Korrespondenz</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'mail-liste' }">
                        <div class="tile bg-turkis inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-envelope-open-text"
                            />
                          </div>
                          <div class="title">Mail</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12" v-if="berechtigungen.m_dokumente.read">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'Dokumentenliste' }">
                        <div class="tile bg-pink inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon icon="fa-duotone fa-star" />
                          </div>
                          <div class="title">Dokumente</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12" v-if="berechtigungen.m_notizen.read">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'notizen-liste' }">
                        <div class="tile bg-pink inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-comments-alt"
                            />
                          </div>
                          <div class="title">Notizen</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-2 col-lg-12">
      <!-- Korrespondenz -->

      <div class="row pt-4" v-if="berechtigungen.m_korrespondenz.read">
        <div class="col-lg-12">
          <div class="tile-categorie-frame">
            <div class="tile-categorie">
              <div class="header">
                <span>Vorlagen</span>
              </div>
              <div class="body">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'Arbeitsplatzliste' }">
                        <div class="tile bg-bright-orange inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon icon="fa-duotone fa-desktop" />
                          </div>
                          <div class="title">
                            <div>Arbeitsplätze</div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'Raumliste' }">
                        <div class="tile bg-bright-orange inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-chalkboard"
                            />
                          </div>
                          <div class="title">Raum</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'Gebäudeliste' }">
                        <div class="tile bg-bright-orange inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon icon="fa-duotone fa-school" />
                          </div>
                          <div class="title">Gebäude</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'einstellungen-sperrtage' }">
                        <div class="tile bg-light-green inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-brackets-square"
                            />
                          </div>
                          <div class="title">
                            <div>Sperrtage</div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-2 col-lg-12">
      <div class="row pt-4" v-if="berechtigungen.m_korrespondenz.read">
        <div class="col-lg-12">
          <div class="tile-categorie-frame">
            <div class="tile-categorie">
              <div class="header">
                <span>Einstellungen</span>
              </div>
              <div class="body">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'mail-vorlagen-liste' }">
                        <div class="tile bg-turkis inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon icon="fa-duotone fa-text" />
                          </div>
                          <div class="title">Mailvorlagen</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link
                        :to="{ name: 'korrespondenz-vorlagen-liste' }"
                      >
                        <div class="tile bg-turkis inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon icon="fa-duotone fa-text" />
                          </div>
                          <div class="title">Korrespondenzvorlagen</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";

import store from "@/store";

import { LOAD_DASHBOARD_INFO } from "@/store/dashboard/actions.type";

export default {
  store,
  data() {
    return {
      statistikAnmeldungen: null,
      isDozent: false,
    };
  },
  computed: {
    dashboardInfo: {
      get() {
        return this.$store.state.dashboard.dashboardInfo;
      },
    },
  },
  created() {
    /* Statistik für Anmeldungen */

    Api.get("/statistik/", { params: { type: "anmeldungen" } }).then(
      (response) => {
        this.statistikAnmeldungen = response.data;
      }
    );

    /* Infoboxen für Frontend */

    if (this.dashboardInfo.studierende == 0)
      this.$store.dispatch(`dashboard/${LOAD_DASHBOARD_INFO}`);
  },
  mounted() {
    if (
      this.gruppen.find(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").INSTRUCTOR
      )
    )
      this.isDozent = true;
  },
};
</script>

<style lang="scss">
</style>
