<template>
  <div class="row">
    <!-- LEFT COLUMN -->

    <div class="col-xl-2 col-lg-12">
      <div class="row pt-4" v-if="berechtigungen.m_geschaeftspartner.read">
        <!-- CRM -->
        <div class="col-lg-12">
          <div class="tile-categorie-frame">
            <div class="tile-categorie">
              <div class="header">
                <span>CRM</span>
              </div>
              <div class="body">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link to="/geschaeftspartner/personenliste">
                        <div class="tile bg-med-green inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-user-friends"
                            />
                          </div>
                          <div class="title">
                            <div>Personen</div>
                          </div>
                          <div class="informations">
                            <div class="row">
                              <div
                                class="col-xl-8 col-lg-8 col-md-8 col-8"
                              ></div>
                              <div class="col-xl-4 col-lg-4 col-md-4 col-4">
                                <router-link
                                  to="/geschaeftspartner/studierende/"
                                >
                                  <div class="information fs-16">
                                    <font-awesome-layers class="fa-lg">
                                      <font-awesome-icon
                                        icon="fa-solid fa-circle"
                                        transform="down-2"
                                      />
                                      <font-awesome-icon
                                        icon="fa-solid fa-user-graduate"
                                        transform="shrink-6 down-2"
                                        :style="{ color: 'white' }"
                                      />
                                    </font-awesome-layers>
                                    <span class="fs-14 ml-1">{{
                                      dashboardInfo.studierende
                                    }}</span>
                                  </div></router-link
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>

                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link to="/geschaeftspartner/adressenliste">
                        <div class="tile bg-med-green inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-user-friends"
                            />
                          </div>
                          <div class="title">
                            <div>{{ $t("global.addresses") }}</div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MID LEFT COLUMN -->

    <div class="col-xl-2 col-lg-12">
      <!-- Bildung -->

      <div class="row pt-4" v-if="berechtigungen.m_bildung.read">
        <div class="col-lg-12">
          <div class="tile-categorie-frame">
            <div class="tile-categorie">
              <div class="header">
                <span>Events</span>
              </div>
              <div class="body">
                <div class="row">
                  <div
                    class="col-xl-12"
                    v-if="berechtigungen.b_bildung_event.read"
                  >
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'event-liste' }">
                        <div class="tile bg-light-blue inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon icon="fa-duotone fa-award" />
                          </div>
                          <div class="title">Events</div>
                        </div>
                      </router-link>
                    </div>
                  </div>

                  <div
                    class="col-xl-12"
                    v-if="berechtigungen.b_bildung_anwesenheit.read"
                  >
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'Anwesenheitsliste' }">
                        <div class="tile bg-ocher inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-calendar-check"
                              transform="shrink-2"
                            />
                          </div>
                          <div class="title">Anwesenheit</div>
                        </div>
                      </router-link>
                    </div>
                  </div>

                  <div
                    class="col-xl-12"
                    v-if="berechtigungen.m_anmeldungen.read"
                  >
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'anmeldungen-liste' }">
                        <div class="tile bg-light-grey-lila inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-sign-in-alt"
                              transform="shrink-2"
                            />
                          </div>
                          <div class="title">
                            {{ $t("global.registrations") }}
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MID RIGHT COLUMN -->

    <div class="col-xl-2 col-lg-12">
      <div class="row pt-4" v-if="berechtigungen.m_finanzen.read">
        <div class="col-lg-12">
          <div class="tile-categorie-frame">
            <div class="tile-categorie">
              <div class="header">
                <span>Finanzen</span>
              </div>
              <div class="body">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'auftraege' }">
                        <div class="tile bg-light-blue inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-file-invoice-dollar"
                            />
                          </div>
                          <div class="title">Aufträge</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="tile-frame left-aligned h-1">
                      <router-link :to="{ name: 'Debitoren' }">
                        <div class="tile bg-light-blue inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-money-bill"
                              transform="left-1"
                            />
                          </div>
                          <div class="title">Debitoren</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="tile-frame left-aligned h-1">
                      <router-link :to="{ name: 'mahnungen' }">
                        <div class="tile bg-light-blue inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-shield-exclamation"
                            />
                          </div>
                          <div class="title">Mahnungen</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="tile-frame left-aligned h-1">
                      <router-link :to="{ name: 'Zahlungen' }">
                        <div class="tile bg-light-blue inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon icon="fa-duotone fa-wallet" />
                          </div>
                          <div class="title">Zahlungs&shy;eingang</div>
                        </div>
                      </router-link>
                    </div>
                  </div>

                  <div class="col-xl-12">
                    <div class="tile-frame left-aligned h-1">
                      <router-link :to="{ name: 'Belege' }">
                        <div class="tile bg-light-blue inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon icon="fa-duotone fa-receipt" />
                          </div>
                          <div class="title">Belege</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Ressourcen -->

    <div class="col-xl-2 col-lg-12">
      <div class="row pt-4" v-if="berechtigungen.m_ressourcen.read">
        <div class="col-lg-12">
          <div class="tile-categorie-frame">
            <div class="tile-categorie">
              <div class="header">
                <span>Ressourcen</span>
              </div>
              <div class="body">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'Terminliste' }">
                        <div class="tile bg-bright-orange inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-calendar-alt"
                            />
                          </div>
                          <div class="title">Termine</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'termin-kalender' }">
                        <div class="tile bg-bright-orange inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-calendar-alt"
                            />
                          </div>
                          <div class="title">Kalender</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'bildschirm-planer' }">
                        <div class="tile bg-bright-orange inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-tv-alt"
                              transform="left-2"
                            />
                          </div>
                          <div class="title">Bildschirme</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- RIGHT COLUMN -->

    <div class="col-xl-2 col-lg-12">
      <!-- Korrespondenz -->

      <div class="row pt-4" v-if="berechtigungen.m_korrespondenz.read">
        <div class="col-lg-12">
          <div class="tile-categorie-frame">
            <div class="tile-categorie">
              <div class="header">
                <span>Kommunikation</span>
              </div>
              <div class="body">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'korrespondenz-liste' }">
                        <div class="tile bg-turkis inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-print"
                              transform="shrink-2"
                            />
                          </div>
                          <div class="title">Korrespondenz</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'mail-liste' }">
                        <div class="tile bg-turkis inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-envelope-open-text"
                              transform="shrink-2"
                            />
                          </div>
                          <div class="title">Mail</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12" v-if="berechtigungen.m_dokumente.read">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'Dokumentenliste' }">
                        <div class="tile bg-pink inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-file-upload"
                              transform="shrink-2"
                            />
                          </div>
                          <div class="title">Dokumente</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-xl-12" v-if="berechtigungen.m_notizen.read">
                    <div class="tile-frame centered h-2">
                      <router-link :to="{ name: 'notizen-liste' }">
                        <div class="tile bg-pink inverted">
                          <div class="icon-container fa-container">
                            <font-awesome-icon
                              icon="fa-duotone fa-comments-alt"
                              transform="shrink-4 left-2"
                            />
                          </div>
                          <div class="title">Notizen</div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

import { LOAD_DASHBOARD_INFO } from "@/store/dashboard/actions.type";

export default {
  store,
  data() {
    return {};
  },
  computed: {
    dashboardInfo: {
      get() {
        return this.$store.state.dashboard.dashboardInfo;
      },
    },
  },
  created() {
    /* Infoboxen für Frontend */

    if (this.dashboardInfo.studierende == 0)
      this.$store.dispatch(`dashboard/${LOAD_DASHBOARD_INFO}`);
  },
  mounted() {},
};
</script>

<style lang="scss">
</style>
