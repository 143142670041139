<template>
  <div class="row pt-1" v-if="berechtigungen.m_ressourcen.read">
    <div class="col-lg-12">
      <div class="tile-categorie-frame">
        <div class="tile-categorie">
          <div class="header">
            <span>{{ $t("global.dashboard_resources") }}</span>
          </div>
          <div class="body">
            <div class="row">
              <div :class="kachelCol">
                <div class="tile-frame h-1" :class="kachelStyle">
                  <router-link :to="{ name: 'Terminliste' }">
                    <div class="tile bg-lila inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-calendar-day"
                          transform="shrink-2"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.dashboard_dates") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div :class="kachelKleinCol">
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <router-link :to="{ name: 'termin-kalender' }">
                    <div class="tile bg-lila inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-calendar-alt"
                          transform="shrink-2"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.dashboard_calendar") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <div
                :class="kachelKleinCol"
                v-if="berechtigungen.m_bildschirm.read"
              >
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <router-link :to="{ name: 'bildschirm-planer' }">
                    <div class="tile bg-lila inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-tv-alt"
                          transform="left-2 shrink-3"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.screens") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'

export default {
  name: "StartseiteRaeumeTermine",
  components: {},
  mixins: [],
  props: {
    kachelStyle: { type: String },
    kachelCol: { type: String },
    kachelKleinStyle: { type: String },
    kachelKleinCol: { type: String },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>
